import React, { useState, createContext } from 'react';
import { Scroll } from 'react-fns';

const PageContext = createContext({
  isScrolled: false,
  // setPageScrolled: () => {},
});
export const PageProvider = ({ children }) => {
  // const [isScrolled, setPageScrolled] = useState(false);
  return (
    <Scroll>
      {({ y }) => {
        const isScrolled = y > 0;
        return (
          <PageContext.Provider
            value={{
              isScrolled,
            }}
          >
            {children}
          </PageContext.Provider>
        );
      }}
    </Scroll>
  );
};

export default PageContext;
